import { Button, Flex, useColorModeValue, Spinner } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

function Dropzone(props: {
  content: JSX.Element | string;
  isLoading?: boolean;
  [x: string]: any;
  handleFileUpload: Function;
}) {
  const { handleFileUpload, content, isLoading, ...rest } = props;

  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('gray.300', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      handleFileUpload(acceptedFiles);
    },
    [handleFileUpload],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf',
    disabled: isLoading,
  });

  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      border="1px dashed"
      borderColor={borderColor}
      borderRadius="16px"
      w="100%"
      maxW="100%"
      h={{ base: '208px', '3xl': '300px' }}
      cursor="pointer"
      {...getRootProps({ className: 'dropzone' })}
      {...rest}
    >
      {isLoading && <Spinner size="xl" color={textColor} />}

      {!isLoading && (
        <>
          <input {...getInputProps()} />
          <Button variant="no-effects">{content}</Button>
        </>
      )}
    </Flex>
  );
}

export default Dropzone;
