import { Box, Icon, Text, useColorModeValue } from '@chakra-ui/react';

import Card from 'components/card/Card';
import Dropzone from 'views/_main/ecommerce/settingsProduct/components/Dropzone';

import { MdOutlineCloudUpload } from 'react-icons/md';

const DropzoneCard = ({
  title,
  handleFileUpload,
  isLoading,
}: {
  [x: string]: any;
}) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brand = useColorModeValue('brand.500', 'brand.400');

  return (
    <Card>
      <Text
        color={textColor}
        fontSize="xl"
        fontWeight="700"
        mb="30px"
        textAlign="center"
      >
        {title}
      </Text>
      <Dropzone
        handleFileUpload={handleFileUpload}
        content={
          <Box maxW="100%">
            {!isLoading && (
              <Icon
                as={MdOutlineCloudUpload}
                w="80px"
                h="80px"
                color={textColor}
              />
            )}

            <Text
              mb="12px"
              fontSize="lg"
              w="100%"
              maxW="100%"
              fontWeight="700"
              color={textColor}
              whiteSpace="pre-wrap"
            >
              Drag and drop your files here, or{' '}
              <Text as="span" fontSize="lg" fontWeight="700" color={brand}>
                click to browse
              </Text>
            </Text>
          </Box>
        }
        isLoading={isLoading}
      />
    </Card>
  );
};

export default DropzoneCard;
