import { useMemo } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';

import { Text, Box, Flex, Button, Grid, GridItem } from '@chakra-ui/react';

import { DeleteIcon } from '@chakra-ui/icons';

import Card from 'components/card/Card';

import FormInput from '../../components/FormInput';
import Divider from '../../components/Divider';
import Message from '../../components/Message';

import { FILE_TYPE } from '../../constants';

import {
  changeRatingInformationDrivingRecord,
  addRatingInformationDrivingRecord,
  removeRatingInformationDrivingRecord,
  changeRatingInformationDiscounts,
  addRatingInformationDiscounts,
  removeRatingInformationDiscount,
  changeRatingInformationDiscountsDiscount,
  addRatingInformationDiscountsDiscount,
  removeRatingInformationDiscountsDiscount,
} from 'reducers/submissionFormReducer';

const RatingInformation = () => {
  const dispatch = useAppDispatch();

  const rating_information_driving_record = useAppSelector(
    (state) => state.submissionForm.data.rating_information_driving_record,
  );

  const rating_information_discounts = useAppSelector(
    (state) => state.submissionForm.data.rating_information_discounts,
  );

  const hasGroupDiscount = useMemo(() => {
    return rating_information_discounts.some((discount) => {
      return discount.discounts.some((subDiscount) => {
        return subDiscount.discount_description
          .toLowerCase()
          .includes('group discount included');
      });
    });
  }, [rating_information_discounts]);

  const onDrivingRecordChange = (event: any, index: number) => {
    const { name, value } = event.target;

    dispatch(changeRatingInformationDrivingRecord({ name, index, value }));
  };

  const addNewDrivingRecord = () => {
    dispatch(addRatingInformationDrivingRecord());
  };

  const removeDrivingRecord = (_: any, index: number) => {
    dispatch(removeRatingInformationDrivingRecord(index));
  };

  const addNewDiscount = () => {
    dispatch(addRatingInformationDiscounts());
  };

  const removeDiscount = (_: any, index: number) => {
    dispatch(removeRatingInformationDiscount(index));
  };

  const onDiscountChange = (event: any, index: number) => {
    const { name, value } = event.target;

    dispatch(changeRatingInformationDiscounts({ name, index, value }));
  };

  const onSubDiscountChange = (
    event: any,
    index: number,
    discountIndex: number,
  ) => {
    const { name, value } = event.target;

    dispatch(
      changeRatingInformationDiscountsDiscount({
        name,
        index,
        discountIndex,
        value,
      }),
    );
  };

  const removeSubDiscount = (_: any, index: number, discountIndex: number) => {
    dispatch(
      removeRatingInformationDiscountsDiscount({ index, discountIndex }),
    );
  };

  const addNewSubDiscount = (index: number) => {
    dispatch(addRatingInformationDiscountsDiscount(index));
  };

  return (
    <Card mb="20px" id="sectionRatingInformation">
      <Text fontWeight="bold" fontSize="2xl" mb="20px">
        Rating Information - AGENT/BROKER AND COMPANY USE ONLY
      </Text>

      <Divider>Driving Record</Divider>

      <Box ml={rating_information_driving_record.length > 1 ? '20px' : ''}>
        {rating_information_driving_record.map((driving_record, index) => {
          return (
            <Box key={index}>
              {rating_information_driving_record.length > 1 && (
                <Box
                  backgroundColor="#ccc"
                  rounded="5px"
                  p="5px"
                  pl="10px"
                  mt="20px"
                >
                  Entry {index + 1}
                </Box>
              )}

              <Grid templateColumns="repeat(10, 1fr)" gap="20px" mt="20px">
                <GridItem colSpan={2} />
                <GridItem
                  colSpan={5}
                  textAlign="center"
                  borderBottom="1px"
                  borderColor="#ccc"
                  pb="5px"
                  mb="5px"
                >
                  Driving Record
                </GridItem>
                <GridItem
                  colSpan={2}
                  textAlign="center"
                  borderBottom="1px"
                  borderColor="#ccc"
                  pb="5px"
                  mb="5px"
                >
                  Driver No.
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(10, 1fr)" gap="20px">
                <FormInput
                  label="Auto(No.)"
                  name="auto_nr"
                  value={driving_record.auto_nr}
                  onChange={(e) => onDrivingRecordChange(e, index)}
                />

                <FormInput
                  label="Class"
                  name="auto_class"
                  value={driving_record.auto_class}
                  onChange={(e) => onDrivingRecordChange(e, index)}
                />

                <FormInput
                  label="BI"
                  name="bi"
                  value={driving_record.bi}
                  onChange={(e) => onDrivingRecordChange(e, index)}
                />

                <FormInput
                  label="PD"
                  name="pd"
                  value={driving_record.pd}
                  onChange={(e) => onDrivingRecordChange(e, index)}
                />

                <FormInput
                  label="AB"
                  name="ab"
                  value={driving_record.ab}
                  onChange={(e) => onDrivingRecordChange(e, index)}
                />

                <FormInput
                  label="DCPD"
                  name="dcpd"
                  value={driving_record.dcpd}
                  onChange={(e) => onDrivingRecordChange(e, index)}
                />

                <FormInput
                  label="Coll/AP"
                  name="coll_ap"
                  value={driving_record.coll_ap}
                  onChange={(e) => onDrivingRecordChange(e, index)}
                />

                <FormInput
                  label="Princ."
                  name="driver_principal"
                  value={driving_record.driver_principal}
                  onChange={(e) => onDrivingRecordChange(e, index)}
                />

                <FormInput
                  label="Sec."
                  name="driver_secondary"
                  value={driving_record.driver_secondary}
                  onChange={(e) => onDrivingRecordChange(e, index)}
                />

                <Button
                  mt="30px"
                  onClick={(e) => removeDrivingRecord(e, index)}
                >
                  <DeleteIcon />
                </Button>
              </Grid>

              <Grid templateColumns="repeat(10, 1fr)" gap="20px" mt="20px">
                <GridItem colSpan={2} />
                <GridItem
                  colSpan={3}
                  textAlign="center"
                  borderBottom="1px"
                  borderColor="#ccc"
                  pb="5px"
                  mb="5px"
                >
                  At-Fault Claim Surcharges
                </GridItem>
                <GridItem
                  colSpan={4}
                  textAlign="center"
                  borderBottom="1px"
                  borderColor="#ccc"
                  pb="5px"
                  mb="5px"
                >
                  Conviction Surcharges
                </GridItem>
              </Grid>

              <Grid templateColumns="repeat(10, 1fr)" gap="20px" mt="20px">
                <GridItem colSpan={2} />
                <GridItem colSpan={3}>
                  <Flex gap="20px">
                    <Box w="80%">
                      <FormInput
                        label="Description"
                        name="at_fault_claim_description"
                        value={driving_record.at_fault_claim_description}
                        onChange={(e) => onDrivingRecordChange(e, index)}
                      />
                    </Box>

                    <Box w="20%">
                      <FormInput
                        label="%"
                        name="at_fault_claim_percentage"
                        value={driving_record.at_fault_claim_percentage}
                        onChange={(e) => onDrivingRecordChange(e, index)}
                      />
                    </Box>
                  </Flex>
                </GridItem>

                <GridItem colSpan={4} gap="20px">
                  <Flex gap="20px">
                    <Box w="80%">
                      <FormInput
                        label="Description"
                        name="conviction_description"
                        value={driving_record.conviction_description}
                        onChange={(e) => onDrivingRecordChange(e, index)}
                      />
                    </Box>

                    <Box w="20%">
                      <FormInput
                        label="%"
                        name="conviction_percentage"
                        value={driving_record.conviction_percentage}
                        onChange={(e) => onDrivingRecordChange(e, index)}
                      />
                    </Box>
                  </Flex>
                </GridItem>

                <GridItem colSpan={1} />
              </Grid>
            </Box>
          );
        })}

        <Box mt="20px">
          <Button variant="brand" onClick={addNewDrivingRecord}>
            Add new
          </Button>
        </Box>
      </Box>

      <Divider mt="20px">Discounts</Divider>

      <Box ml={rating_information_discounts.length > 1 ? '20px' : ''}>
        {rating_information_discounts.map((discount, index) => {
          return (
            <Box key={index}>
              {rating_information_discounts.length > 1 && (
                <Box
                  backgroundColor="#ccc"
                  rounded="5px"
                  p="5px"
                  pl="10px"
                  mt="20px"
                >
                  Entry {index + 1}
                </Box>
              )}

              <Grid templateColumns="repeat(10, 1fr)" gap="20px" mt="20px">
                <GridItem colSpan={3} />
                <GridItem
                  colSpan={4}
                  textAlign="center"
                  borderBottom="1px"
                  borderColor="#ccc"
                  pb="5px"
                  mb="5px"
                >
                  Rate Group
                </GridItem>
                <GridItem colSpan={3} />
              </Grid>

              <Grid templateColumns="repeat(10, 1fr)" gap="20px" mt="20px">
                <FormInput
                  label="Auto(No.)"
                  name="conviction_description"
                  value={discount.auto_nr}
                  onChange={(e) => onDiscountChange(e, index)}
                />

                <FormInput
                  label="List Price New"
                  name="list_price_new"
                  value={discount.list_price_new}
                  onChange={(e) => onDiscountChange(e, index)}
                />

                <FormInput
                  label="Vehicle Code"
                  name="vehicle_code"
                  value={discount.vehicle_code}
                  onChange={(e) => onDiscountChange(e, index)}
                />

                <FormInput
                  label="AB"
                  name="ab"
                  value={discount.ab}
                  onChange={(e) => onDiscountChange(e, index)}
                />

                <FormInput
                  label="DCPD"
                  name="dcpd"
                  value={discount.dcpd}
                  onChange={(e) => onDiscountChange(e, index)}
                />

                <FormInput
                  label="Coll/AP"
                  name="coll_ap"
                  value={discount.coll_ap}
                  onChange={(e) => onDiscountChange(e, index)}
                />

                <FormInput
                  label="Comp/SP"
                  name="comp_sp"
                  value={discount.comp_sp}
                  onChange={(e) => onDiscountChange(e, index)}
                />

                <FormInput
                  label="Location"
                  name="location"
                  value={discount.location}
                  onChange={(e) => onDiscountChange(e, index)}
                />

                <FormInput
                  label="Territory"
                  name="territory"
                  value={discount.territory}
                  onChange={(e) => onDiscountChange(e, index)}
                />

                <Button mt="30px" onClick={(e) => removeDiscount(e, index)}>
                  <DeleteIcon />
                </Button>
              </Grid>

              <Grid templateColumns="repeat(10, 1fr)" gap="20px" mt="20px">
                <GridItem colSpan={4} />
                <GridItem
                  colSpan={5}
                  textAlign="center"
                  borderBottom="1px"
                  borderColor="#ccc"
                  pb="5px"
                  mb="5px"
                >
                  Discounts
                </GridItem>
                <GridItem colSpan={1} />
              </Grid>

              {discount.discounts.map((subDiscount, subIndex) => {
                return (
                  <Grid
                    templateColumns="repeat(10, 1fr)"
                    gap="20px"
                    mt="20px"
                    key={subIndex}
                  >
                    <GridItem colSpan={4} />
                    <GridItem colSpan={4}>
                      <FormInput
                        label="Description"
                        name="discount_description"
                        value={subDiscount.discount_description}
                        onChange={(e) =>
                          onSubDiscountChange(e, index, subIndex)
                        }
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <FormInput
                        label="%"
                        name="discount_percentage"
                        value={subDiscount.discount_percentage}
                        onChange={(e) =>
                          onSubDiscountChange(e, index, subIndex)
                        }
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      {subIndex !== 0 && (
                        <Button
                          mt="30px"
                          onClick={(e) => removeSubDiscount(e, index, subIndex)}
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                    </GridItem>
                  </Grid>
                );
              })}

              <Grid templateColumns="repeat(10, 1fr)" gap="20px" mt="20px">
                <GridItem colSpan={4} />
                <GridItem colSpan={6}>
                  <Button
                    variant="brand"
                    onClick={() => addNewSubDiscount(index)}
                  >
                    Add new
                  </Button>
                </GridItem>
              </Grid>
            </Box>
          );
        })}

        <Box mt="20px">
          <Button variant="brand" onClick={addNewDiscount}>
            Add new
          </Button>
        </Box>

        {hasGroupDiscount && (
          <>
            <Divider mt="20px" mb="20px">
              Group Discount
            </Divider>
            <Message
              validationId="group_discount"
              manual={{
                message:
                  'Group Discount found on quote. Please upload membership evidence or group discount form as proof.',
                show: true,
              }}
              file={FILE_TYPE.TYPE_GROUP_DISCOUNT_EVIDENCE}
            />
          </>
        )}
      </Box>
    </Card>
  );
};

export default RatingInformation;
